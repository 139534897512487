import { Box, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MobileFooter } from "./components/MobileFooter";
import { useEffect, useState } from "react";
import { DesktopHeader } from "./components/DesktopHeader";
import { DesktopFooter } from "./components/DesktopFooter";
import { SharedByPopup } from "./components/SharedByPopup";
import { MediaViewer } from "./components/MediaViewer";
import { useNavigate, useParams } from "react-router-dom";
import {
  ModelPerson,
  useMediaSharedDataGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import SGLogoLoader from "@assets/images/SGLogoLoader.gif";

interface StyledBoxProps {
  isMobile: boolean;
}

const StyledBox = styled(Box)<StyledBoxProps>`
  height: fit-content;
  min-height: 100vh;
  background-color: #fff;
  .react-transform-wrapper {
    width: 100% !important;
  }
  .react-transform-component {
    width: 100% !important;
  }
`;
const StyledSGLogoLoader = styled(Box)`
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  img {
    width: 150px;
  }
  @media (max-width: 465px) {
    img {
      width: 100px;
    }
  }
`;

export const Media = () => {
  const navigate = useNavigate();
  const { compressedUrl } = useParams();
  const [showMobileFooter, setShowMobileFooter] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [mobileUrl, setMobileUrl] = useState("");

  useEffect(() => {
    const checkMobile = () => {
      const userAgent = window.navigator.userAgent;
      setIsMobile(/Mobile/i.test(userAgent));
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  const {
    data: media,
    isLoading: isLoading,
    error: error
  } = useMediaSharedDataGet(compressedUrl as string);

  useEffect(() => {
    if (compressedUrl) {
      setMobileUrl(`sportsgravy://media?id=${compressedUrl}`);
    }
  }, [compressedUrl]);

  useEffect(() => {
    if (error) navigate("/not-found");
  }, [error]);

  if (isLoading) {
    return (
      <StyledSGLogoLoader>
        <img src={SGLogoLoader} />
      </StyledSGLogoLoader>
    );
  }

  return (
    <>
      <StyledBox
        isMobile={isMobile}
        style={{
          backgroundColor: isMobile ? "#000" : "#fff"
        }}
      >
        {!isMobile && <DesktopHeader isMobile={isMobile} />}
        <Container
          style={{
            ...(isMobile ? { padding: "0px" } : {}),
            marginTop: isMobile ? "0" : "15px"
          }}
        >
          <Box
            style={{
              backgroundColor: "#000"
            }}
          >
            {media?.data?.media && (
              <MediaViewer
                mediaFiles={media.data.media}
                currentIndex={0}
                isMobile={isMobile}
              />
            )}
          </Box>
          {isMobile && showMobileFooter && (
            <MobileFooter
              mobileUrl={mobileUrl}
              toggleMobileFooter={setShowMobileFooter}
            />
          )}
          {!isMobile && <DesktopFooter />}
        </Container>
        {isMobile && !showMobileFooter && (
          <SharedByPopup
            mobileUrl={mobileUrl}
            user={media?.data.person as ModelPerson}
          />
        )}
      </StyledBox>
    </>
  );
};
